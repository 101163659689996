/*eslint-disable no-unused-vars*/

import { createApp } from 'vue'
import Maska from 'maska'
import form from '@/store/form'
import './assets/js/css/ImportCss'
import Cookies from 'js-cookie'
import { Fancybox } from '@fancyapps/ui'
import SwiperSlider from '@/assets/js/Swiper/Swiper'
import HeaderFixed from '@/assets/js/Header/Fixed/HeaderFixed'
import ButtonTop from '@/assets/js/Button/Top/ButtonTop'
import ButtonPopup from '@/assets/js/Button/Popup/ButtonPopup'
import ButtonTimetable from '@/assets/js/Button/Timetable/ButtonTimetable'
import MapIframe from '@/assets/js/Map/Iframe/MapIframe'
import ButtonMobile from '@/assets/js/Button/Mobile/ButtonMobile'
import ProductGallery from '@/assets/js/Product/Gallery/ProductGallery'
import VideoImg from '@/assets/js/Video/VideoImg/VideoImg'
import VideoPlayer from '@/assets/js/Video/Player/VideoPlayer'
import Program from '@/assets/js/Program/Program'
import Stock from '@/assets/js/Stock/Stock'
import StockYear from '@/assets/js/Stock/StockYear'
import AppForm from '@/components/Form/AppForm.vue'

// Подключаем глобально jQuery
const $ = window.$ = window.jQuery = require('jquery')

// Подключаем глобально Cookie
window.Cookies = Cookies

document.addEventListener('DOMContentLoaded', () => {
    Fancybox.bind('[data-fancybox]', {
        mainClass: 'fancybox--popup'
    })

    Fancybox.bind('[data-fancybox-stock]', {
        mainClass: 'fancybox--stock'
    })

    new SwiperSlider()
    new HeaderFixed()
    new ButtonTop()
    new ButtonPopup()
    new ButtonTimetable()
    new MapIframe()
    new ButtonMobile()
    new ProductGallery()
    new VideoImg()
    new VideoPlayer()
    new Program()
    new Stock()
    new StockYear()

    if(document.getElementById('block--form-order') != null) {
        createApp({})
            .component('block-form', AppForm)
            .use(Maska)
            .use(form)
            .mount('#block--form-order')
    }

    if(document.getElementById('block--form-book') != null) {
        createApp({})
            .component('block-form', AppForm)
            .use(Maska)
            .use(form)
            .mount('#block--form-book')
    }

    if(document.getElementById('block--form-review') != null) {
        createApp({})
            .component('block-form', AppForm)
            .use(Maska)
            .use(form)
            .mount('#block--form-review')
    }
})
